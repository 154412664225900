@media only screen and (max-width: 600px) {
    h2 {
        font-size: 2.5rem;
    }
    h2::after {
        left: 0;
        width: 100%;
        height: 0.3rem;
    }
    h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
}

@media only screen and (min-width: 600.05px) and (max-width: 768px) {
    h2 {
        font-size: 3rem;
    }
}
@media only screen and (min-width: 600px) {
    h2::after {
        left: -5%;
        width: 110%;
        height: 0.5rem;
    }
    h3 {
        font-size: 1.8rem;
        margin-bottom: 1.5rem;
    }
}
@media only screen and (min-width: 768.05px) and (max-width: 1024px) {
    h2 {
        font-size: 3.5rem;
    }
}
@media only screen and (min-width: 1024.05px) {
    h2 {
        font-size: 4rem;
    }
}

html {
    /*scroll-behavior: smooth;*/
}

.App {
    font-family: 'Open Sans', sans-serif;
}

div, article {
    box-sizing: border-box;
}

h1, h2 {
    font-family: 'Rustico', serif;
    font-weight: 400;
    line-height: 1.1;
    margin: 0;
}

h2 {
    position: relative;
    display: inline-block;
    transform: rotate(-3deg);
}

h2::after {
    content: "";
    position: absolute;
    bottom: -0.5rem;
    background-image: url(./img/underline.svg);
    background-repeat: no-repeat;
}

h3 {
    font-weight: 800;
    line-height: 1.3;
    margin-top: 0;
}

h4 {
    font-size: 1.5rem;
    margin: 0 0 0.5rem;
}

.line-break {
    display: block;
}

img {
    width: 100%;
}

figure {
    margin: 0;
}

p {
    margin: 0 0 1rem;
    line-height: 1.6;
}

ul {
    margin: 0;
    padding: 0;
}

li {
    list-style: none;
}

a {
    color: #000000;
    text-decoration: none;
}

.visually-hidden {
    position: absolute !important;
    height: 1px;
    width: 1px;
    overflow: hidden;
    clip: rect(1px 1px 1px 1px); /* IE6, IE7 */
    clip: rect(1px, 1px, 1px, 1px);
    white-space: nowrap; /* added line */
}

.image-gallery .carousel.carousel-slider {
    overflow: visible;
}